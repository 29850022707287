/*global $:false*/
/*global escape: true */
/*jslint browser: true*/

var clickToCall = (function($) {
    var clickToCall = function() {
        /*
        This is a global click to call function. This will match phone numbers in these formats and convert them to anchors with 'tel' attributes:

        123 456 7890
        (123)456 7890
        (123) 456 7890
        (123)456-7890
        (123) 456-7890
        123.456.7890
        123-456-7890
        *This also accounts for the '1-800' scenario with a space, dot or dash separating the '1' and '800'

        Example: <a href="tel:1234567890">(123) 456-7890</a>
        */

        var phoneRegEx = new RegExp(/(1[\s.-])?([(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4})(?![^<]*>|[^<>]*<\/)/g);

        /*
        RegEx Explained

        /           Start the expression
        (1[\s.-])?  An optional '1' followed by an optional space, dot or dash (to be used in '1-800')
        [(]?        Optional left parenthesis
        \d{3}\      Look for 3 digits (area code)
        [)]?        Optional right parenthsis
        [(\s)?.-]   Optional space (after the area code) or a dot or dash
        \d{3}       Look for 3 digits (the first part of the phone num)
        [\s.-]      Either a space, dot, or dash to separate the first 3 numbers of the phone number
        \d{4}       The last 4 digits of the phone num
        (?![^<]*>|[^<>]*<\/)  This is a negative lookup. Make sure the number is not directly nested in any tag. Ex: a tag with an ID or class that has digits in this format
        /g          Global search

        */

        //search through the immediate children of the body and construct a new jQuery object from the matching elements.
        $("body *:not(iframe):not(script)").contents().each(function() {
            //return if nodeType isn't a text node (Text Node: Represents textual content in an element or attribute. No children.)
            if (this.nodeType != 3) {
                return;
            }
            //we don't want to change phone numbers that are already in a link
            if ($(this).closest('a').length) {
                return;
            }
            //test against the regEx
            var match = $(this).text().match(phoneRegEx);
            if (match === null || match.length === 0) {
                return;
            }
            var newhtml = $(this).text().replace(phoneRegEx, function(match) {
                //remove spaces, dashes, dots and parenthesis for the href
                var phonenumber = match.replace(/ /g, "").replace(/-/g, "").replace(/\./g, "").replace(/\(/g, "").replace(/\)/g, "");
                var link = '<a href="tel:' + phonenumber + '">' + match + '</a>';
                return link;
            });
            $(this).replaceWith(newhtml);
        });
    };

    /*
    This function is used for the 'click to call'. This can be used for all mobile devices.
    IOS automatically converts phone numbers, but does not recognize all phone formats (EX: 555-555-5555)
    so applying this for IOS will ensure all phone number get converted. This plugin will not break the default number conversion by IOS.
    Here is an example for calling this on IOS and Android devices:
    */

    var isAppleDevice = function() {
        "use strict";
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    };

    var isAndroidDevice = function() {
        "use strict";
        if (/Android/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    };

    /*
    If you are using infinite scrolling where new phone numbers may be added after the script has run, or other dynamic phone numbers that are populated via script after page load, you’ll need to make sure to add those as well.
    I added this functionality for the infinite scrolling for providers and locations for Milford just so we don’t have to scan the entire page again when new entries are added:
    */

    function infiniteScrollClickToCall() { //use this for infinite scrolling instead of looking through the entire site each time
        $('.DrListContainer ul li span.Phone, .LocationsList ul li span.Phone').each(function() { //only look for phone numbers that have a class of phone (this should be all of them)
            if ($(this).find('a').length === 0) { //disregard phone numbers that are already in an anchor
                var regex = /([(]?\d{3}[)]?)[(\s)?.-](\d{3})[\s.-](\d{4})/g; // Adding phone # links around phone numbers
                var text = $(this).html();
                text = text.replace(regex, "<a href='tel:$1$2$3'>$&</a>");
                $(this).html(text);
            }
        });
    }

    return {
        isAppleDevice: isAppleDevice,
        isAndroidDevice: isAndroidDevice,
        infiniteScrollClickToCall: infiniteScrollClickToCall,
        clickToCall: clickToCall
    };
})(jQuery);

var equalHeight = (function($) {
    "use strict";

    // remove inline min height from the group
    function clear(group) {
        group.css('min-height', '');
    }

    // make group equal heights
    function equalHeight(group) {

        // reset height set by this script
        clear(group);

        // defining a variable that will keep track of the height of the tallest element
        var tallest = 0;

        // loop through elements, find the tallest outer height (includes padding)
        group.each(function () {

            // this is the outer height of the element (it doesn't round up or down to whole numbers)
            var thisHeight = this.getBoundingClientRect().bottom - this.getBoundingClientRect().top;

            if (thisHeight > tallest) {
                tallest = thisHeight;
            }
        });

        // loop through elements again, individually set their min-height so that their total height (including padding) = our tallest element
        group.each(function () {

            // if this has css box-sizing: border box, set the min-height equal to the tallest
            if (isBorderBox(this)) {

                group.css('min-height', Math.ceil(tallest));

            } else {

                // if an element has padding
                if ($(this).outerHeight() > $(this).height()) {

                    // calulate how much border and padding is on the element
                    var thisPadding = $(this).outerHeight() - $(this).height();

                    // set the height of the element to the tallest, but leave room for the padding
                    group.css('min-height', Math.ceil(tallest - thisPadding));

                } else {

                    // if the element has no padding, simply set the height to our tallest
                    group.css('min-height', Math.ceil(tallest));
                }
            }
        });
    }

    // check to see if the page is using box-sizing: border-box;
    function isBorderBox(elem) {
        return window.getComputedStyle(elem).boxSizing === "border-box";
    };

    return {
        equalHeight: equalHeight,
        outerHeight: equalHeight,
        clear: clear
    };

})(jQuery);

//-------------------------------------------------
//		youtube playlist jquery plugin
//		Created by dan@geckonm.com
//		www.geckonewmedia.com
//
//		v1.1 - updated to allow fullscreen 
//			 - thanks Ashraf for the request
//-------------------------------------------------
//modified by Geonetric to include iframe instead of old embed code.

jQuery.fn.ytplaylist = function (options) {

	// default settings
	var options = jQuery.extend({
		holderId: 'yt_holder',
		playerHeight: '300',
		playerWidth: '450',
		addThumbs: false,
		thumbSize: 'small',
		showInline: false,
		autoPlay: false,
		showRelated: false,
		allowFullScreen: true
	}, options);

	return this.each(function () {
		var selector = $(this);
		var autoPlay = "";
		var showRelated = "?rel=0";
		var fullScreen = "";
		if (options.autoPlay) autoPlay = "&autoplay=1";
		if (options.showRelated) showRelated = "?rel=1";
		if (options.allowFullScreen) fullScreen = "allowfullscreen";

		//throw a youtube player in
		function play(id) {
			var html = '';

			html += '<iframe height="' + options.playerHeight + '" width="' + options.playerWidth + '" frameborder="0" src="//www.youtube-nocookie.com/embed/' + id + showRelated + autoPlay + '" ' + fullScreen + '></iframe>';

			return html;

		};

		//grab a youtube id from a (clean, no querystring) url (thanks to http://jquery-howto.blogspot.com/2009/05/jyoutube-jquery-youtube-thumbnail.html)
		function youtubeid(url) {
			if (url.indexOf("watch") >= 0) {
				var ytid = url.match("[\\?&]v=([^&#]*)");
				ytid = ytid[1];
				return ytid;
			} else {
				var ytid = url.split('/');
				ytid = ytid[3];
				return ytid;
			}
		};


		//load inital video
		var firstVid = selector.children("li:first-child").addClass("currentvideo").children("a").attr("href");
		$("#" + options.holderId + "").html(play(youtubeid(firstVid)));

		//load video on request
		selector.children("li").children("a").click(function () {

			if (options.showInline) {
				$("li.currentvideo").removeClass("currentvideo");
				$(this).parent("li").addClass("currentvideo").html(play(youtubeid($(this).attr("href"))));
			}
			else {
				$("#" + options.holderId + "").html(play(youtubeid($(this).attr("href"))));
				$(this).parent().parent("ul").find("li.currentvideo").removeClass("currentvideo");
				$(this).parent("li").addClass("currentvideo");
			}
			return false;
		});

		//do we want thumbs with that?
		if (options.addThumbs) {

			selector.children().each(function (i) {

				var replacedText = $(this).text();

				if (options.thumbSize == 'small') {
					var thumbUrl = "http://img.youtube.com/vi/" + youtubeid($(this).children("a").attr("href")) + "/2.jpg";
				}
				else {
					var thumbUrl = "http://img.youtube.com/vi/" + youtubeid($(this).children("a").attr("href")) + "/0.jpg";
				}


				$(this).children("a").empty().html("<img src='" + thumbUrl + "' alt='' />" + replacedText);

			});
		}
	});
};

function convertNumberToWords(amount) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }
        value = "";
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Lakhs ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string;
}